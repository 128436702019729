<template>
	<div class="grid crud-demo">
		<div class="col-12">
			<div class="card">
				<Toast/>
				<Toolbar class="mb-4">
					<template v-slot:start>
						<div class="my-2">
							<Button label="Neu" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
							<Button label="Löschen" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected" :disabled="!selectedUnternehmen || !selectedUnternehmen.length" />
						</div>
					</template>

					<template v-slot:end>
						<Button label="Export" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)"  />
					</template>
				</Toolbar>

				<DataTable ref="dt" :value="unternehmen" v-model:selection="selectedUnternehmen" dataKey="ID" :paginator="true" :rows="10" :filters="filters"
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
							currentPageReportTemplate="{first} bis {last} von {totalRecords} Unternehmen" responsiveLayout="scroll">
					<template #header>
						<div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
							<h5 class="m-0">Unternehmen</h5>
							<span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Suchen..." />
                            </span>
						</div>
					</template>

					<Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
					<Column field="ID" header="ID" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">ID</span>
							{{slotProps.data.ID}}
						</template>
					</Column>
					<Column field="NAME" header="Name" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">Name</span>
							{{slotProps.data.NAME}}
						</template>
					</Column>
					<Column field="ORT" header="Ort" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">Ort</span>
							{{slotProps.data.ORT}}
						</template>
					</Column>
					<Column field="PLZ" header="PLZ" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">PLZ</span>
							{{slotProps.data.PLZ}}
						</template>
					</Column>
          <Column field="STRASSE" header="Strasse" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Strasse</span>
              {{slotProps.data.STRASSE}}
            </template>
          </Column>
          <Column field="NR" header="Nummer" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Nummer</span>
              {{slotProps.data.NR}}
            </template>
          </Column>
					<Column>
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editUnternehmen(slotProps.data)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="confirmDeleteUnternehmen(slotProps.data)" />
						</template>
					</Column>
				</DataTable>

				<Dialog v-model:visible="unternehmenDialog" :style="{width: '450px'}" header="Unternehmen Details" :modal="true" class="p-fluid">
					<div class="field">
						<label for="NAME">Name</label>
						<InputText id="name" v-model.trim="unternehmenObj.NAME" required="true" autofocus :class="{'p-invalid': submitted && !unternehmenObj.NAME}" />
						<small class="p-invalid" v-if="submitted && !unternehmenObj.NAME">Name muss angegeben werden.</small>
					</div>
          <div class="field">
            <label for="ORT">Ort</label>
            <InputText id="ort" v-model.trim="unternehmenObj.ORT" required="true" autofocus :class="{'p-invalid': submitted && !unternehmenObj.ORT}" />
            <small class="p-invalid" v-if="submitted && !unternehmenObj.ORT">ORT muss angegeben werden.</small>
          </div>
          <div class="field">
            <label for="PLZ">PLZ</label>
            <InputText id="plz" v-model.trim="unternehmenObj.PLZ" required="true" autofocus :class="{'p-invalid': submitted && !unternehmenObj.PLZ}"/>
            <small class="p-invalid" v-if="submitted && !unternehmenObj.PLZ">Postleitzahl muss angegeben werden.</small>
          </div>
          <div class="field">
            <label for="STRASSE">Strasse</label>
            <InputText id="strasse" v-model.trim="unternehmenObj.STRASSE" required="true" autofocus :class="{'p-invalid': submitted && !unternehmenObj.STRASSE}" />
            <small class="p-invalid" v-if="submitted && !unternehmenObj.STRASSE">Strasse muss angegeben werden.</small>
          </div>
          <div class="field">
            <label for="NR">Nummer</label>
            <InputText id="nr" v-model.trim="unternehmenObj.NR" required="true" autofocus :class="{'p-invalid': submitted && !unternehmenObj.NR}" />
            <small class="p-invalid" v-if="submitted && !unternehmenObj.NR">Hausnummer muss angegeben werden.</small>
          </div>
					<div class="field">
						<div class="formgrid grid">
							<div class="field-checkbox col-6">
								<Checkbox id="auftraggeber" name="AUFTRAGGEBER" value="true" v-model="unternehmenObj.auftraggeber" :binary="true" />
								<label for="AUFTRAGGEBER">Auftraggeber</label>
							</div>
							<div class="field-checkbox col-6">
								<Checkbox id="auftragnehmer" name="AUFTRAGNEHMER" value="true" v-model="unternehmenObj.auftragnehmer" :binary="true" />
								<label for="AUFTRAGNEHMER">Auftragnehmer</label>
							</div>
						</div>
					</div>
					<template #footer>
						<Button label="Abbrechen" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
						<Button label="Speichern" icon="pi pi-check" class="p-button-text" @click="saveUnternehmen" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteUnternehmenDialog" :style="{width: '450px'}" header="Achtung!" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="unternehmenObj"><b>{{unternehmenObj.NAME}}</b> wirklich löschen?</span>
					</div>
					<template #footer>
						<Button label="Nein" icon="pi pi-times" class="p-button-text" @click="deleteUnternehmenDialog = false"/>
						<Button label="Ja" icon="pi pi-check" class="p-button-text" @click="deleteUnternehmen" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteUnternehmensDialog" :style="{width: '450px'}" header="Achtung!" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="unternehmenObj">Ausgewählte Unternehmen wirklich löschen?</span>
					</div>
					<template #footer>
						<Button label="Nein" icon="pi pi-times" class="p-button-text" @click="deleteUnternehmensDialog = false"/>
						<Button label="Ja" icon="pi pi-check" class="p-button-text" @click="deleteSelectedUnternehmen" />
					</template>
				</Dialog>
			</div>
		</div>
	</div>

</template>

<script>
import {FilterMatchMode} from 'primevue/api';
import DataService from "@/service/DataService";

export default {
	data() {
		return {
      unternehmen: null,
      unternehmenDialog: false,
			deleteUnternehmenDialog: false,
			deleteUnternehmensDialog: false,
      neu: false,
      unternehmenObj: {},
			selectedUnternehmen: null,
			filters: {},
			submitted: false
		}
	},
  dataService: null,
	created() {
    this.dataService = new DataService();
		this.initFilters();
	},
	async mounted() {
    this.unternehmen = await this.dataService.getUnternehmen();
  },
	methods: {
		openNew() {
			this.unternehmenObj = {};
			this.submitted = false;
			this.unternehmenDialog = true;
      this.neu = true;
		},
		hideDialog() {
			this.unternehmenDialog = false;
			this.submitted = false;
		},
    async saveUnternehmen(){
      this.submitted = true;
      if(this.neu){
        if((this.unternehmenObj.NAME.toString().length) >= 1){
          await this.dataService.saveUnternehmen(this.unternehmenObj);
          this.$toast.add({severity:'success', summary: 'Erfolgreich', detail: 'Unternehmen erstellt', life: 3000});
          setTimeout(async()=> {this.unternehmen = await this.dataService.getUnternehmen()}, 100);
          this.unternehmenDialog = false;
          this.unternehmenObj = {};
        }
      } else {
        if((this.unternehmenObj.NAME.toString().length) >= 1) {
          await this.dataService.updateUnternehmen(this.unternehmenObj);
          this.$toast.add({severity:'success', summary: 'Erfolgreich', detail: 'Unternehmen aktualisiert', life: 3000});
          setTimeout(async()=> {this.unternehmen = await this.dataService.getUnternehmen()}, 100);
          this.unternehmenDialog = false;
          this.unternehmenObj = {};
        }
      }

    },
    editUnternehmen(unternehmen) {
      this.unternehmenObj = {...unternehmen};
      this.unternehmenDialog = true;
      this.neu = false;
    },
    confirmDeleteUnternehmen(unternehmen) {
      this.unternehmenObj = unternehmen;
      this.deleteUnternehmenDialog = true;
    },
    async deleteUnternehmen() {
      let data = [];
      data.push(this.unternehmenObj);
      await this.dataService.deleteUnternehmen(data);

      this.deleteUnternehmenDialog = false;
      this.unternehmenObj = {};
      this.$toast.add({severity:'success', summary: 'Erfolgreich', detail: 'Unternehmen gelöscht', life: 3000});
      setTimeout(async()=> {this.unternehmen = await this.dataService.getUnternehmen()}, 100);

    },
		exportCSV() {
			this.$refs.dt.exportCSV();
		},
		confirmDeleteSelected() {
			this.deleteUnternehmensDialog = true;
		},
    async deleteSelectedUnternehmen() {
      this.deleteUnternehmensDialog = false;
      await this.dataService.deleteUnternehmen(this.selectedUnternehmen);
      this.selectedUnternehmen = null;
      this.$toast.add({severity:'success', summary: 'Erfolgreich', detail: 'Unternehmen gelöscht', life: 3000});
      setTimeout(async()=> {this.unternehmen = await this.dataService.getUnternehmen()}, 100);

    },
		initFilters() {
            this.filters = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            }
        }
	}
}
</script>

<style scoped lang="scss">
@import '../assets/demo/styles/badges.scss';
</style>
